import { Global } from '@emotion/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { AppProvider } from './contexts/AppContext';
import globalStyles from './emotion/globalStyles';
import './fonts/PPRadioGrotesk/index.css';
import ErrorView from './views/ErrorView';
// import App from "./App";
import SingleProposal, { loader as rootLoader } from './views/SingleProposal';

const router = createBrowserRouter([
  // {
  //   path: "/",
  //   element: <App />,
  // },
  {
    path: '/show/:proposalId',
    element: <SingleProposal />,
    errorElement: <ErrorView />,
    loader: rootLoader,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppProvider>
      <Global styles={globalStyles} />
      <RouterProvider router={router} />
    </AppProvider>
  </React.StrictMode>,
);
